import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: '22px',
    alignItems: 'baseline',
    marginTop: 0,
    justifyContent: props => {
      if (props.align === 'center') {
        return 'center';
      }

      return 'initial';
    },
  },
}));
const HeaderTitleDesc = ({ title, align }) => {
  const classes = useStyles({ align });
  
  return (
    <p className={classes.root}>{title}</p>
  )
}

HeaderTitleDesc.defaultProps = {
  title: '',
  align: 'initial',
};

HeaderTitleDesc.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string,
}

export default HeaderTitleDesc;
